// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-aktuelles-ansicht-js": () => import("../src/templates/aktuelles/ansicht.js" /* webpackChunkName: "component---src-templates-aktuelles-ansicht-js" */),
  "component---src-templates-general-general-js": () => import("../src/templates/general/general.js" /* webpackChunkName: "component---src-templates-general-general-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuelles-js": () => import("../src/pages/aktuelles.js" /* webpackChunkName: "component---src-pages-aktuelles-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-mitglieder-branchen-js": () => import("../src/pages/mitglieder/branchen.js" /* webpackChunkName: "component---src-pages-mitglieder-branchen-js" */),
  "component---src-pages-mitglieder-index-js": () => import("../src/pages/mitglieder/index.js" /* webpackChunkName: "component---src-pages-mitglieder-index-js" */),
  "component---src-pages-termine-js": () => import("../src/pages/termine.js" /* webpackChunkName: "component---src-pages-termine-js" */),
  "component---src-pages-ueber-uns-js": () => import("../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */)
}

